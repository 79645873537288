import Vue from 'vue'
import App from './App.vue'
import store from './store'

Vue.config.productionTip = false

// Bootstrap Vue
import './theme.scss'
import {
	BFormRadio,
	BFormRadioGroup,
	BNavbar,
	BNavbarBrand,
	BNavbarNav,
	BButton,
	BContainer,
	BModal,
	BCard,
	BCardHeader,
	BCardBody,
	BCollapse,
	VBToggle,
	BIconCaretRight,
	BIconCaretDown,
	BButtonToolbar,
	BButtonGroup,
	BDropdown,
	BDropdownItem,
	BDropdownDivider,
	BDropdownGroup,
	VBModal,
	BRow,
	BCol,
	BFormSelect,
	BProgress,
	BProgressBar,
	BSpinner,
	BFormTextarea,
	BIconX,
	BFormGroup,
	BFormInput,
	BFormTags,
	BAlert
} from '@iebh/bootstrap-vue';
// Radio buttons
Vue.component('b-form-radio', BFormRadio);
Vue.component('b-form-radio-group', BFormRadioGroup);
// Navbar
Vue.component('b-navbar', BNavbar);
Vue.component('b-navbar-brand', BNavbarBrand);
Vue.component('b-navbar-nav', BNavbarNav);
// Button
Vue.component('b-button', BButton);
// File Upload
Vue.component('b-container', BContainer);
// Warning modal
Vue.component('b-modal', BModal);
// Refs
Vue.component('b-card', BCard);
Vue.component('b-card-header', BCardHeader);
Vue.component('b-card-body', BCardBody);
Vue.component('b-collapse', BCollapse);
Vue.directive('b-toggle', VBToggle );
Vue.component('BIconCaretRight', BIconCaretRight);
Vue.component('BIconCaretDown', BIconCaretDown);
// Toolbar
Vue.component('b-button-toolbar', BButtonToolbar);
Vue.component('b-button-group', BButtonGroup);
Vue.component('b-dropdown', BDropdown);
Vue.component('b-dropdown-item', BDropdownItem);
Vue.component('b-dropdown-divider', BDropdownDivider);
Vue.component('b-dropdown-group', BDropdownGroup);
Vue.directive('b-modal', VBModal );
Vue.component('b-row', BRow );
Vue.component('b-col', BCol );
Vue.component('b-form-select', BFormSelect);
// Progress
Vue.component('b-progress', BProgress );
Vue.component('b-progress-bar', BProgressBar );
Vue.component('b-spinner', BSpinner );
// Edit Reference
Vue.component('b-form-textarea', BFormTextarea );
Vue.component('b-icon-x', BIconX );
Vue.component('b-form-group', BFormGroup );
Vue.component('b-form-input', BFormInput );
Vue.component('b-form-tags', BFormTags );
// Toolbar limit warning
Vue.component('b-alert', BAlert);

// Import $tera / @iebh/Tera-fy global service
// NOTE: See bottom of file inside main async() init loop for when TeraFy actually boots
import TeraFy from "@iebh/tera-fy/dist/terafy.es2019.js"; // FIX: Use annoyingly old and specific version as Babel struggles with ESNEXT class imports
import TerafyVue from "@iebh/tera-fy/dist/plugin.vue2.es2019.js";

let terafy = new TeraFy()
	.set("devMode", process.env.VUE_APP_TERAFY_DEV)
	.setIfDev("verbosity", process.env.VUE_APP_TERAFY_VERBOSITY)
	.use(TerafyVue); // Add the Vue plugin

Vue.config.productionTip = false;

(async ()=> {
	const app = window.app = new Vue({
		render: h => h(App),
		store,
	});

	// Boot teraFy + require project + pull & subscribe to project data
	await terafy.init({
		app, // Provide app to bind against
		Vue // Provide the vue version to use
	});

	app.$mount("#app");
})()
