<template>
	<div class="mt-5">
		<b>Choose a library to begin searching</b>
		<tera-file-select
			:title="'Choose a citation library...'"
			:filters="{library: true}"
			placeholder="Choose a citation library..."
			@change="fileInputEvent($event)"
		/>
		<b-button v-if="file" variant="success" class="mt-3" @click="fileUpload">Upload</b-button>
	</div>
</template>

<script>
import importMixin from "../mixins/importMixin"
import TeraFileSelect from '@iebh/tera-fy/widgets/tera-file-select.vue';

export default {
	components: {
		TeraFileSelect
	},
	data() {
		return {
			file: null,
		}
	},
	name: 'FileUpload',
	mixins: [importMixin],
	methods: {
		fileInputEvent(file) {
			this.file = file;
		},
		fileUpload() {
			if (this.file) {
				// Read file extention
				// const fileExtention = this.getFileExtention(this.file.name);
				this.importFile(this.file);
			}
		}
	}
}
</script>

<style>
</style>
