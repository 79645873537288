import serverMixin from "./serverMixin";

export default {
	mixins: [serverMixin],
	methods: {
		importFile(file) {
			this.$root.$emit('set-progress', { stage: "parsing", progress: 0 });
			this.$root.$emit('set-loading', true);

			return Promise.resolve()
				.then(()=> file.getRefs())
				.then(refs => {
					// Calculate pmids and dois
					const ids = this.calculateIds(refs);
					// Update refs object with pmids and mutated dois
					refs = ids.refs;
					// Get server to query lens
					this.citationSearch(ids.pmids, ids.dois)
						.then(response => {
							if (response.data.error) {
								console.error(response.data.error);
								this.$root.$emit('set-loading', false);
								return;
							}
							// Input IDs loaded by lens
							const inputIds = response.data.inputIds;
							// Input object which will be set to store
							var input = {
								loaded: [],
								manual: []
							}
							// Calculate input refs (which ones need to be manually searched)
							refs.forEach(ref => {
								if (inputIds.some(id => id.toLowerCase() == ref.pmid?.toLowerCase() || id.toLowerCase() == ref.doi?.toLowerCase())) {
									input.loaded.push(ref);
								} else {
									input.manual.push(ref);
								}
							})
							// Set store
							this.$store.commit('references/setRefs', {
								input,
								output: {
									citations: response.data.citations,
									references: response.data.references
								}
							});
							// Set loading to false
							this.$root.$emit('set-loading', false);
						})
				})
				.catch(err => {
					console.log(err);
					if (err.message) {
						alert("Error:" + err.message);
					} else {
						alert("Error:", err);
					}
					// Set loading to false
					this.$root.$emit('set-loading', false);
				})
		},
	}
}
