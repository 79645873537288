import axios from 'axios';

export default {
		methods: {
			calculateIds(refs) {
				if (refs) {
					let pmids = [];
					let dois = [];
					refs.forEach(article => {
						// PMID
						let pmid = "";
						if(article.urls && article.urls.length > 0) {
							article.urls.forEach(url => {
								const match = /ncbi\.nlm\.nih\.gov\/pubmed\/(\d+)/gi.exec(url);
								pmid = match && match[1] ? match[1] : "";
							})
						}
						if (pmid.length > 0) {
							article.pmid = pmid;
							pmids.push(pmid);
							return;
						}
						// DOI
						if(article.doi && !article.doi.includes(',')) {
							var doi = "";
							var match = /doi\s+([^\s]+)/i.exec(article.doi)
							if (match && match[1]) {
								doi = match[1];
							} else {
								doi = article.doi;
							}
							if (doi.length > 0) {
								article.doi = doi;
								dois.push(doi);
								return;
							}
						}
					});
					return { refs, pmids, dois };
				} else {
					return { refs: null, pmids: [], dois: [] };
				}
			},
			citationSearch(pmids, dois) {
				return axios({
					method: 'post',
					baseURL: process.env.VUE_APP_BASE_URL,
					url: process.env.VUE_APP_API_URL,
					headers: {
						'Content-Type': 'application/json',
					},
					data: {
						pmids: pmids,
						dois: dois
					}
				})
			},
			getReferences(idArray) {
				return new Promise((resolve, reject) => {
					axios({
						method: 'post',
						baseURL: process.env.VUE_APP_BASE_URL,
						url: "/api/get-references",
						headers: {
							'Content-Type': 'application/json'
						},
						data: {
							idArray: idArray
						}
					})
						.then(response => {
							if (response.data.error) {
								reject(response.data.error);
							} else if (Array.isArray(response.data)) {
								resolve(response.data.map(this.mutateLensObject));
							} else {
								reject("Other error");
							}
						})
						.catch(err => reject(err));
				})
			},
			mutateLensObject(ref) {
				// Year
				if (ref.year_published) {
					ref.year = ref.year_published;
				}
				// URLS
				if (ref.source_urls && ref.source_urls.length > 0) {
					ref.urls = ref.source_urls.map(url => url.url);
				}
				// DOI
				if (ref.external_ids && ref.external_ids.length > 0) {
					ref.external_ids.forEach(id => {
						if (id.type == "doi") {
							ref.doi = id.value;
							return;
						}
					})
				}
				// Authors
				if (ref.authors && ref.authors.length > 0) {
					ref.authors = ref.authors.map(author => {
						if (author.last_name) {
							return author.last_name + (author.initials ? `, ${author.initials}.` : '');
						} else {
							return '';
						}
					})
				}
				// Pages
				if (ref.start_page) {
					ref.pages = ref.start_page + (ref.end_page ? `-${ref.end_page}` : '');
				}
				// Date
				if (ref.date_published_parts && ref.date_published_parts.length > 0) {
					ref.date = ref.date_published_parts.join("/");
				}
				// Journal & ISSN
				if (ref.source) {
					if (ref.source.title) {
						ref.journal = ref.source.title;
					}
					if (ref.source.issn && ref.source.issn[0].value) {
						ref.isbn = ref.source.issn[0].value
					}
				}
				// Work Type (e.g. review)
				if (ref.publication_supplementary_type && ref.publication_supplementary_type.length > 0) {
					ref.workType = ref.publication_supplementary_type.join(", ");
				}
				// Language
				if (ref.languages && ref.languages.length > 0) {
					ref.language = ref.languages.join(", ")
				}
				// Keywords
				var keywordSet = new Set();
				if (ref.keywords) {
					keywordSet = new Set(ref.keywords);
				}
				if (ref.fields_of_study && ref.fields_of_study.length > 1) {
					ref.fields_of_study.forEach(keywordSet.add, keywordSet)
				}
				if (ref.chemicals && ref.chemicals.length > 0) {
					ref.chemicals.forEach(chemical => {
						if (chemical.substance_name) {
							keywordSet.add(chemical.substance_name);
						}
					})
				}
				// Ref Type (e.g. Journal Article)
				if (ref.publication_type) {
					switch (ref.publication_type) {
						case "journal article":
							ref.type = "journalArticle";
							break;
						case "book chapter":
							ref.type = "bookSection";
							break;
						case "book":
							ref.type = "book";
							break;
						case "conference proceedings":
							ref.type = "conferenceProceedings";
							break;
						case "dissertation":
							ref.type = "thesis";
							break;
						case "preprint":
							ref.type = "unpublished";
							break;
						case "clinical trial":
							ref.type = "journalArticle";
							break;
					}
				}
				ref.keywords = [ ...keywordSet ];
				return ref;
			},
		},
}